import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {claimPoints, getTasks, setInteraction} from "../../logic/server/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTelegram} from "../../hooks/useTelegram";
const CollectiblesPage = () => {
    const {webApp, user} = useTelegram();
    // const [tasks, setTasks] = useState([]);
    const [collectibles, setCollectibles] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);

    useEffect(() => {
        // getTasksRequest();
    }, []);

    // async function handleClaim(task) {
    //     const res = await claimPoints( localStorage.getItem('token'), task.id);
    //     if (res?.status) {
    //         setTaskClaimed([{id: task.id, status: true}]);
    //     }
    // }

    return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--top'}>
                    <div className={'card__tasks--title '}>

                        <p className={'text-bold-roboto'}>Inventory</p>
                    </div>
                    <div className={'cards__container'}>
                        {collectibles.map((item) => (
                            <div className={'new-picklezone-card__task'}
                                 key={item}
                            >

                                <div className={'picklezone-card__column-to-row'}>
                                    <p className={'picklezone-card__task--text'}>Picklezone Pad #8</p>
                                    <p className={'small-card-task-text'}>Equip for 1 day</p>
                                    <p className={'small-card-task-text'}>Sell for 10 Pickles</p>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'collectibles'}/>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default CollectiblesPage;
