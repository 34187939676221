import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";

import {useDispatch, useSelector} from "react-redux";
import {increment, incrementByAmount, setAmount} from "./counterSlice";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {
    animated,
    useChain, useSpring,
    useSpringRef, useSprings, useSpringValue,
    useTransition
} from '@react-spring/web';
import timer from '../../assets/stopwatch.svg';
import lives from '../../assets/lives-icon.svg';
import HeaderFunction from "../../components/app/functions/HeaderFunction";
import Splash from "../splash/splash";
import {openApp} from "./settingsSlice";
import Pickleball from "./pickleball";
import {ReactSVG} from "react-svg";
import {useTelegram} from "../../hooks/useTelegram";
import {DropI, PickleballClass, PickleballI} from "./pickleballLogic";
import paddleOne from  "../../assets/pink-paddle-1.png";
import paddleTwo from  "../../assets/paddle_1.png";
import paddleThree from  "../../assets/paddle_3.png";
import paddleDefault from  "../../assets/paddle-default.svg";
import background from  "../../assets/game-backgound.png";
import backgroundOne from  "../../assets/orange-2-bg.png";
import backgroundV from  "../../assets/background-vertical.png";
import {claimPoints, claimSeveralPoints, getGameReward, getNextBall} from "../../logic/server/api";
import flame from '../../assets/flame-2.svg'
import rarePaddle from '../../assets/super-rare-paddle.svg'

const AnimationScreen= React.memo(({collectibles}: any) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    // custom cursor
    const [MousePosition, setMousePosition] = useState({
        left: -200,
        top: -200
    });
    const [paddle, setPaddle] = useState(paddleThree);
    const [reward, setReward] = useState('');

    const [ballType, set] = useState<PickleballI | null>(null);
    const [nextBall, setNextBall] = useState<PickleballI | null>(null);
    const [startedBall, setStartedBall] = useState<PickleballI | null>(null);
    const [springOpacity, setOpacity] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false); // Track animation state
    const [resetAnimation, setResetAnimation] = useState(false);
    const [timer, setTimer] = useState<any>(null);
    const [isClicked, setIsClicked] = useState(false);
    const [points, setPoints] = useState(0);
    const [coordinates, setCoordinates] = useState({x: 0, y: 0});

    const size = useSpringValue(100, {
        config: {
            mass: 0.1,
            tension: 320,
            duration: 200
        },
    });
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const [paddleProps, apiPaddle] = useSpring(
        (points: any) => ({
            from: { transform: 'rotate3d(0, 0, 0, 0deg)' }, // Start with no rotation
            to: [
                { transform: 'rotate3d(1, 0, 1, 0deg)' },  // Tilt around both X and Y axes (simulate hit)
                { transform: 'rotate3d(1, 0, 1, 0deg)' },   // Return to original position
            ],
            config: { tension: 200, friction: 15 },
        })
    );

    const clickTransitions = useTransition(isClicked, {
        from: { transform: 'rotate3d(0, 0, 0, 0deg)' },
        to: [
            { transform: 'rotate3d(0, 0, 1, 5deg)' },
            { transform: 'rotate3d(0, 0, 1, 0deg)' },
        ],
        config: { tension: 200, friction: 15 },
    });


    function handleMouseMove(ev: any) {
        setMousePosition({left: ev.pageX, top: ev.pageY, });
    }
    function handleBGClick(ev: Event) {
        apiPaddle.start({
            from: { transform: 'rotate3d(0, 0, 0, 0deg)' }, // Start with no rotation
            to: [
                { transform: 'rotate3d(1, 1, 0, 30deg)' },  // Tilt around both X and Y axes (simulate hit)
                { transform: 'rotate3d(1, 1, 0, 0deg)' },   // Return to original position
            ],
            config: { tension: 200, friction: 15 },
        });

        if (isMobile) {
            setTimeout(() => {
                setMousePosition({left: -200, top: -200 });
            }, 1000);
        }
    }

    useEffect(() => {
        // first ball
        getNextPickleball();
        // for cursor
        const userAgent = navigator.userAgent || navigator.vendor;
        // Check for common mobile platforms
        if (/android/i.test(userAgent)) {
            setIsMobile(true);
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            setIsMobile(true);
        }

        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('touchmove', handleMouseMove);
        };

    }, []);

    useEffect(() => {
        // console.log('effect', isAnimating);
        if (!isAnimating) {
            // second and next pickleballs
            // console.log( 'second-next');
            getNextPickleball();
        }
    }, [isAnimating]);

    useEffect(() => {
        if ( nextBall && (!startedBall || (startedBall.dropId !== nextBall.dropId)) ) {
            // console.log('nextBall', nextBall);
            set(nextBall);
            size.start(nextBall.height);
            setResetAnimation(false);
            setIsFetching(false);
            if (timer) {
                clearTimeout(timer);
            }
            const newTimer = setTimeout(() => {
                setOpacity(1);
                transitionApi.start();
            }, 0);
            setTimer(newTimer);
        }
    }, [nextBall]);

    const getNextPickleball = async () => {
        setIsFetching(true);
        setResetAnimation(true);
        transitionApi.stop();
        const newBallInfo: any | DropI = await getNextBall();
        if (newBallInfo?.status) {
            // console.log('newBallInfo', newBallInfo, newBallInfo.drop.reward?.data?.value)
            const value = newBallInfo.drop.reward?.data?.value ? newBallInfo.drop.reward?.data?.value : 0;
             const ball = new PickleballClass(windowWidth, windowHeight, value, newBallInfo.drop?.id);
            // console.log('ball-to-effect', ball)
            setNextBall(ball);
        }
    }

    // animations

    // splash screen
    const springApi = useSpringRef();
    const transitionsFirst = useTransition(0, {
        ref: springApi,
        key: 0,
        from: { opacity: 1, scale: 1},
        enter: { opacity: 0.5, scale: 1 },
        leave: { opacity: 0,  scale: 0 },
        config: { duration: 1500 },
        onRest: ( item) => {
            setOpen(true);
            dispatch(openApp());
        },
    });

    const transitionApi = useSpringRef();
    const isAppOpenedSaved = useSelector(state => (state as any).settings.isOpened);
    const isOpenedState = useMemo(() => !!isAppOpenedSaved, [isAppOpenedSaved]);

    // ball movement
    const transitions = useTransition(
         ballType && !isAnimating ? ballType : 0, {
            ref: transitionApi,
            from: { transform: "translateY(-100%)", opacity: 0 },  // start off-screen above
            enter: { transform: "translateY(0%)", opacity: 1 },     // transition to the normal position
            leave: { transform: "translateY(110%)", opacity: 1 },   // leave off-screen below
            config: {duration: ballType?.duration ? ballType.duration : 2000, mass: 20, tension: 500, friction: 0 },
            key: (item: any) => {
                // console.log('item', item);
                // console.log('key', ballType?.dropId ? ballType?.dropId : '123');
                return ballType?.dropId ? ballType?.dropId : '123'},
            reset: resetAnimation,
            onRest: (item: any) => {
                // console.log('rest');
                setIsAnimating(false);
            },
            onChange: () => {
                // console.log('change');
                // change height??
            },
            onStart: () => {
                // console.log('start');
                setStartedBall(ballType);
                setIsAnimating(true);
                // console.log('animation has started');
            },
        });
    // splash then ball animation
    useChain(open  ? [springApi] : [springApi, transitionApi], [0, 0.8]); // Delay between the two animations

    // get click event from the Pickleball child component
    const updateParentClick = (event: {event: any, item: PickleballI}) => {
        apiPaddle.start({
        from: { transform: 'rotate3d(0, 0, 0, 0deg)' },
        to: [
            { transform: 'rotate3d(1, 0, 1, 20deg)' },
            { transform: 'rotate3d(1, 0, 1, 0deg)' },
        ],
            config: { tension: 200, friction: 15, duration: 1500 },
        });
        const x = event.event.clientX;
        const y = event.event.clientY;

        size.start(1.8 * event.item.height);
        setCoordinates({x,y});

        setTimeout(() => {
            transitionApi.stop();
            setOpacity(0);
            setIsAnimating(false);

            claimRequest(event.item);
        }, 200);
    };
    async function claimRequest(item: PickleballI) {
        if (localStorage.getItem('token')) {
            // setReward("../../assets/pink-paddle-1.png");

            const resp: any = await getGameReward(item?.dropId);
            if (resp && resp.status && resp.reward?.data?.collection_id) {

                const rewardInfo = collectibles.find((item: any) => item.item_id === resp.reward.data.item_id);
                if (rewardInfo) {
                    setReward(rewardInfo.image);
                    setPaddle(rewardInfo.image);
                }
            } else if (resp && resp.status && resp.reward?.data?.value) {

                const points = resp.reward.data.value;
                setPoints(points);
                dispatch(incrementByAmount(points));
                setIsClicked(true);
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
            }
        }
    }
    function onCloseReward() {
        setReward('');
        transitionApi.start();
    }


    return !open && !isOpenedState ? (
        <div style={{ display: 'flex', 'alignItems': 'center', 'height': '100%'}}>
            {transitionsFirst((style, i) => (
                <animated.div style={{...style, position: 'absolute', top: 0, left: 0,}}>
                    <Splash style={{...style}}
                    ></Splash>
                </animated.div>))}
        </div>) : (
        <TelegramScreen
            className={`main-wrapper--inactive main-wrapper animation-screen`}  id='gesuredZone'
            style={{cursor: 'none', backgroundImage: `url(${backgroundV}`}}
            onMouseMove={(ev: any)=> handleMouseMove(ev)}
            onTouchMove={(ev: any)=> handleMouseMove(ev)}
            onClick={(ev: any) => handleBGClick(ev)}
        >
            <animated.div style={paddleProps}>
                <div  className="cursor"
                  style={{left: MousePosition.left ,
                      top: MousePosition.top,
                      backgroundImage: `url(${paddle})`
                  }}
            ></div>
            </animated.div>

            <div className='main-container'>
                <HeaderFunction></HeaderFunction>
                {!isFetching  && (
                <div className={'animation-container'}>
                    {transitions((style, item, t, index) => (
                        item ? (<animated.div style={{...style, opacity: springOpacity, zIndex: 1000}}>
                         <Pickleball item={ballType} springOpacity={springOpacity}
                                        size={size}
                                        onChange={updateParentClick}
                            ></Pickleball>
                        </animated.div>) : null
                    ))}
                </div>)}
                <div>
                    {clickTransitions((style, clicked) =>
                            clicked && (
                                <animated.div
                                    className='pickleScore'
                                    style={{
                                        ...style,
                                        top: coordinates?.y ? (coordinates.y + 10)  : '150px',
                                        left: coordinates?.x ? (coordinates.x - 30) : '150px',
                                        transform: 'rotate(-12deg)',
                                        zIndex: 1000
                                    }}
                                >
                                    {((points /3 ) === 2) && <div className='pickleScore--min'>double points</div>}
                                    {((points /3 ) === 3) && <div className='pickleScore--min-3'>triple points</div>}
                                    <div>+ {points} DINKS</div>
                                </animated.div>
                            )
                    )}
                </div>
                {reward && (
                   <div className={'success-container'}>
                   <div style={{position: 'relative', marginTop: '40%'}}>
                       <img alt='reward' src={reward} style={{width: '200px', height: '200px' }}/>
                       {/*<img src={`url(${reward})`}/>*/}
                       <div className={'success-label'}>Super rare</div>
                   </div>

                    <div className={'success-card'}>
                        <p className={'success-card--title'}>You’ve received a drop!</p>
                        <p className={'success-card--text'}>Pickleball Pad #7 worth of 110 PZN</p>
                        <div className={'success-btns'}>
                            <button className={'success-btns--collect'} onClick={() => onCloseReward()}>Collect</button>
                            <button className={'success-btns--sell'} onClick={() => onCloseReward()}>Sell</button>
                        </div>
                    </div>
                </div>)}

                <div className='forms__bottom'>
                    {/*<div className='cards__bottom--main'>*/}
                    {/*    <div className='card__bottom--main'>*/}
                    {/*        /!*<TimerIcon></TimerIcon>*!/*/}
                    {/*        <ReactSVG  src={timer}></ReactSVG>*/}
                    {/*        <p>15:12:49</p>*/}
                    {/*    </div>*/}
                    {/*    <div className='card__bottom--main'>*/}
                    {/*        <ReactSVG  src={lives}></ReactSVG>*/}

                    {/*        /!*<LivesIcon></LivesIcon>*!/*/}
                    {/*        <p>17 lives</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <FooterFunction currentPage={'main'}/>

                </div>
            </div>
        </TelegramScreen>
    );
});

export default AnimationScreen;
